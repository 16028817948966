import React, { useEffect, useState, useContext, useRef } from 'react';
import { withRouter, useHistory, useParams, BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { io } from "socket.io-client";
import $ from 'jquery';
import { useQuery } from 'react-query';
import Dropzone from 'dropzone';
import "dropzone/dist/dropzone.css";
import { Document, Page, pdfjs } from 'react-pdf'
import {NotificationContainer, NotificationManager} from 'react-notifications';

import { NavigationContext } from '../components/NavigationContext';
import { BrokerContext } from '../components/BrokerContext';
import { PlanContext, ClientContext, ApplicationContext } from '../components/PCAContext';

import './pages.css';
import './Applications.css';
import 'react-notifications/lib/notifications.css';

import Sidebar           from '../components/Sidebar';
import Headbar           from '../components/Headbar';
import SetEnvironment    from '../components/SetEnvironment';
import LoadingOverlay    from '../components/LoadingOverlay';
import PlanBox from '../components/PlanBox';
import PickPlan from '../pages/subpages/Applications/PickPlan';
import PlanFilters from '../components/PlanFilters';

import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
// import Button from '@mui/material/Button';

const uiavatars = require("ui-avatars");

const fetchPlans = async (key, type, num, zip, age, gender, county_id="", agency_id=-1) => {
  let obj = {
      "plan_type": type,
      "plan_number": num,
      "plan_zip": zip,
      "plan_age": age,
      "plan_gender": gender,
      "plan_county_id": county_id,
      "agency_id": agency_id,
  }

  let json = JSON.stringify(obj);
  const res = await fetch('/api/get_plans', {
      method: 'POST',
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: json
  });
  return res.json();
}

const fetchCustomBrokerPDFs = async (key, broker_id) => {
  let obj = {
      "broker_id": broker_id,
  }
  let json = JSON.stringify(obj);

  const res = await fetch('/api/get_custom_broker_pdfs', {
      method: 'POST',
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: json
  });
  return res.json();
}

function LiveSalesSessionBrokerView(props) {
    let history = useHistory();

    const [broker, setBroker] = useContext(BrokerContext);
    const [plan, setPlan] = useContext(PlanContext);
    const [client, setClient] = useContext(ClientContext);
    const [application, setApplication] = useContext(ApplicationContext);

    const [loading, setLoading] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [currentWindowState, setCurrentWindowState] = useState("plans");
    const [planFilterSubpage, setPlanFilterSubpage] = useState(true);
    const [planFilters, setPlanFilters] = useState({});
    const [logMessageRoom, setLogMessageRoom] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef();

    const [selectedPlans, setSelectedPlans] = useState([]);
    const [currentlySharedPlans, setCurrentlySharedPlans] = useState([]);
    const currentlySharedPlansRef = useRef(selectedPlans);
    const [highlightedPlan, setHighlightedPlan] = useState();

    const [usersInRoom, setUsersInRoom] = useState([{username: "You", sid: ""}]);
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const [previewingPdfFile, setPreviewingPdfFile] = useState("");
    const [sharedPdfFile, setSharedPdfFile] = useState("");
    const [sharedPdfData, setSharedPdfData] = useState("");
    const currentlySharedPdfDataRef = useRef(sharedPdfData);
    const [sharedPdfFileBlob, setSharedPdfFileBlob] = useState(null);
    const [currentlySharedPdfPages, setCurrentlySharedPdfPages] = useState([]);
    const [numPages, setNumPages] = useState(0);
    const [pageNumsToShare, setPageNumsToShare] = useState([]);
    const pageNumsToShareRef = useRef(pageNumsToShare);
    const [pageNumsInTextBox, setPageNumsInTextBox] = useState("");
    const [uploadOverlayOn, setUploadOverlayOn] = useState(false);
    const [documentOverlayOn, setDocumentOverlayOn] = useState(false);

    const [socket, setSocket] = useState(null);

    // const { data: queryData, status } = useQuery(['plans', planType, planNum, planZip, planAge, planGender, planCountyId, broker.agency_id], fetchPlans);
    const { data: queryData, status } = useQuery(['plans', planFilters.type, planFilters.plan_number, planFilters.zip, planFilters.age, planFilters.gender, planFilters.county_id, broker.agency_id], fetchPlans);
    const { data: customBrokerPDFs, status: customBrokerPDFStatus, refetch: refetchCustomPDFs } = useQuery(['custom_pdfs', broker.id], fetchCustomBrokerPDFs); 

    function handleSelectPlan(event) {
      if (event.currentTarget !== null && event.currentTarget.value !== null) {
        console.log("TARGET!");
        console.log(event.currentTarget);
        let pl=JSON.parse(event.currentTarget.value);
        if (!event.currentTarget.checked) {
          setSelectedPlans(oldPls => {
            for (let op in oldPls) {
              if (oldPls[op].id === pl.id) {
                console.log("rm");
                oldPls.splice(op, 1);
              }
            }
            return oldPls;
          })
        }
        else {
          setSelectedPlans([...selectedPlans, pl]);
        }
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const room = urlParams.get('room');

    useEffect(() => {
      localStorage.debug = '*';
      console.log("Starting socket.io client...");
      console.log("SOCKET OBJECT");
      
      let sock = io();
      setSocket( sock );
      console.log(sock);
      // Event Listeners
      sock.on('connect', function() {
        console.log("Connected!");
        sock.emit('broker_join', {username: broker.first_name + " " + broker.last_name, agency_name: broker.agency_name, room: room});
      });
      sock.on('print_to_log', function(msg) {
        console.log("print_to_log: ", msg);
        setMessages(prevMessages => [...prevMessages, msg]);
        if (messagesEndRef.current !== null && messagesEndRef.current !== undefined) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });
      sock.on('plan_highlighted', function(pl) {
        console.log("plan_highlighted: ", pl);
        setHighlightedPlan(pl);
      });
      sock.on('client_disconnect', function(data) {
        console.log("client_disconnect: ", data);
        setUsersInRoom(prevUsers => {
          for (let u in prevUsers) {
            if (prevUsers[u].sid === data.client_sid) {
              createNotification(prevUsers[u].username + " has left the meeting.", "info");
              prevUsers.splice(u, 1);
            }
          }
          return prevUsers;
        });
      })
      sock.on('request_join', function(data) {
        console.log(data['username'] + " wants to join the room. " + data['sid']);
        console.log("sending them: ");
        console.log(currentlySharedPlansRef.current !== null ? currentlySharedPlansRef.current.plans : "");
        console.log(currentlySharedPdfDataRef.current);      

        let plansToSend = [];
        if (currentlySharedPlansRef.current !== null) {
          for (let p in currentlySharedPlansRef.current.queryData) {
            for (let s in currentlySharedPlansRef.current.plans) {
              if (currentlySharedPlansRef.current.queryData[p].id == currentlySharedPlansRef.current.plans[s].id) {
                plansToSend.push(currentlySharedPlansRef.current.queryData[p]);
                break;
              }
            }
          }
        }
        
        console.log(plansToSend);

        sock.emit('current_room_state', {room: room, plans: plansToSend, pages: pageNumsToShareRef.current, document: currentlySharedPdfDataRef.current, messages: messages});
        setUsersInRoom(prevUsers => [...prevUsers, {username: data['username'], sid: data['sid']}]);
        createNotification(data['username'] + " has joined the meeting.", "info");
      })

      sock.emit('log', {data: 'Meeting started.', room: room, broadcast: true});
      sock.emit('log', {data: broker.first_name + " " + broker.last_name + ' connected to the room.', room: room, broadcast: true});

      return () => {
        console.log("Disconnected!");
        sock.emit('leave', {username: broker.first_name + " " + broker.last_name, room: room});

        window.addEventListener("beforeunload", function(e) {
          let confirmationMessage = "o/End meeting?";
          socket.emit('end_meeting', {room: room});
    
          (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    
          console.log("logout !");
          return confirmationMessage; //Webkit, Safari, Chrome
        
        });
      }
    }, []);

    useEffect(() => {
      if (uploadOverlayOn === true) {
        Dropzone.options.fileUploadArea = {
          accept: function(file, done) {
            setUploadInProgress(true);
            console.log("uploadPdfFile: ");
            console.log(file);
            file.arrayBuffer().then(buffer => {
              let bytes = new Uint8Array(buffer);
              let len = bytes.byteLength;
              let binary = '';
              for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
              }
              
              var base64EncodedStr = window.btoa(binary);
              
              let json = JSON.stringify({
                "broker_id": broker.id,
                "file_name": broker.id + "_" + file.name,
                "file": base64EncodedStr,
                "bucket": "user-pdfs",
              });
              fetch('/api/upload_pdf', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: json
              }).then(res => res.json())
              .then(data => {
                setUploadInProgress(false);
                done("Upload successful.");
              })
            });
          },
          createImageThumbnails: true,
          acceptedFiles: ".pdf",
        }
        let myDropzone = new Dropzone("#fileUploadArea");
        myDropzone.on("addedfile", file => {
          console.log(`File added: ${file.name}`);
          });
      }
      else {
        refetchCustomPDFs();  
      }
    }, [uploadOverlayOn])

    useEffect(() => {
      console.log(messages);
    }, [messages]);

    useEffect(() => {
      console.log(broker);
    }, [broker]);

    useEffect(() => {
      console.log(pageNumsInTextBox);
    }, [pageNumsInTextBox]);

    useEffect(() => {
      console.log("SELECTED PLANS")
      console.log(selectedPlans);
    }, [selectedPlans]);


    const sendPlansToClient = () => {
      let plansToSend = [];
      for (let p in queryData) {
        for (let s in selectedPlans) {
          if (queryData[p].id == selectedPlans[s].id) {
            plansToSend.push(queryData[p]);
            break;
          }
        }
      }
      console.log("Sending to client: ", plansToSend);
      socket.emit('show_plans_list', {plans: plansToSend, room: room});

      currentlySharedPlansRef.current = 
      {
        plans: plansToSend,
        queryData: queryData,
      }
      setCurrentlySharedPlans(plansToSend);

      setSharedPdfFile("");
      setSharedPdfData(null);
      currentlySharedPdfDataRef.current = null;
      setSharedPdfFileBlob(null);
      setCurrentlySharedPdfPages([]);
    }

    const isPlanCurrentlyShared = (plan) => {
      for (let p in currentlySharedPlansRef.current.plans) {
        if (currentlySharedPlansRef.current.plans[p].id === plan.id) {
          return true;
        }
      }
      return false;
    }

    
    const printToLogRoom = () => {
      console.log("printToLogRoom: ", logMessageRoom);
      socket.emit('log', {data: logMessageRoom, room: room, sender: broker.first_name + " " + broker.last_name, websocket_id: broker.id, broadcast: true});
    }

    const getPdfFile = (file) => {
      setSharedPdfFileBlob(null);
      setPreviewLoading(true);
      let json = JSON.stringify({
        bucket: "user-pdfs",
        file: file,
      });
      fetch('/api/download_pdf', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
      }).then(res => res.json())
      .then(data => {
        setPreviewLoading(false);
        setPreviewingPdfFile(file);
        setSharedPdfData(data);
        console.log(data);
        var xhr = new XMLHttpRequest();
          xhr.open("GET", data);
          xhr.responseType = "arraybuffer";
  
          xhr.onload = function () {
              if (this.status === 200) {
                  var blob = new Blob([xhr.response], {type: "application/pdf"});
                  console.log(blob);
                  var objectUrl = URL.createObjectURL(blob);
                  setSharedPdfFileBlob(blob);
              }
          };
          xhr.send();
        
      });
    }

    // Sends an existing uploaded file from AWS to the client
    const sendPdfFile = () => {
      socket.emit('pdf_file', {file: sharedPdfData, pages: pageNumsToShare, room: room});
      setCurrentlySharedPdfPages(pageNumsToShare);
      pageNumsToShareRef.current = pageNumsToShare;
      setPageNumsToShare([]);
      currentlySharedPdfDataRef.current = sharedPdfData;
      
      setSharedPdfFile(previewingPdfFile);
      setPreviewingPdfFile("");

      setSelectedPlans([]);
      currentlySharedPlansRef.current = null;
      setCurrentlySharedPlans([]);
      setHighlightedPlan(undefined);

      setDocumentOverlayOn(false);
      setPageNumsInTextBox("");
    }

    const previewFile = (file) => {
      setDocumentOverlayOn(true);
      getPdfFile(file);
    }

    const renameFile = (file) => {
      let newName = prompt("Enter new name for file: ");
      if (newName !== null) {
        let json = JSON.stringify({
          bucket: "user-pdfs",
          file: file,
          new_file_name: broker.id + "_" + newName + ".pdf",
          broker_id: broker.id
        });
        fetch('/api/rename_pdf', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: json
        }).then(res => res.json())
        .then(data => {
          alert("Rename successful.")
        });
      }
    }

    const deleteFile = (file) => {
      if (window.confirm("Are you sure you want to delete this file?") == true) {
        let json = JSON.stringify({
          bucket: "user-pdfs",
          file: file,
          broker_id: broker.id
        });
        fetch('/api/delete_pdf', {
          method: 'POST',
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: json
        }).then(res => res.json())
        .then(data => {
          console.log("Delete successful.")
        });
      }
    }

    const endMeeting = () => {
      setCurrentWindowState("");
      socket.emit('end_meeting', {room: room});
      setLoading(true);
      fetch('/api/update_broker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          id: broker.id,
          live_session_client_name: null,
          live_session_create_date: null,
          live_session_room_id: null,
        })
      }).then(res => res.json())
      .then(data => {
        setBroker({
          ...broker,
          live_session_client_name: null,
          live_session_create_date: null,
          live_session_room_id: null,
        });
        history.push('/meetings')
      });
    }

    function numArrayToRangeString(arr) {
      // Converts an sorted array of numbers to a string of ranges
      // The array is assumed to be sorted in ascending order
      // Example: [1,2,3,4,5,6,7,8,9,10] => "1-10"
      // Example: [1,2,3,6,7,8,9,10] => "1-3, 6-10"
      let str = "";
      let start = arr[0];
      let end = arr[0];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i] === arr[i-1] + 1) {
          end = arr[i];
        } else {
          if (start === end) {
            str += (start + 1) + ", ";
          } else {
            str += (start + 1) + "-" + (end + 1) + ", ";
          }
          start = arr[i];
          end = arr[i];
        }
      }
      if (start === end) {
        str += (start + 1) + ", ";
      } else {
        str += (start + 1) + "-" + (end + 1) + ", ";
      }
      return str.slice(0, -2);
    }

    function rangeStringToNumArray(str) {
      // Does the opposite conversion as numArrayToRangeString
      // Example: "1-10" => [1,2,3,4,5,6,7,8,9,10]
      // Example: "1-3, 6-10" => [1,2,3,6,7,8,9,10]
      let arr = [];
      let ranges = str.split(",");
      for (let i = 0; i < ranges.length; i++) {
        let range = ranges[i].split("-");
        if (range.length === 1) {
          arr.push(parseInt(range[0]));
        } else {
          for (let j = parseInt(range[0]); j <= parseInt(range[1]); j++) {
            arr.push(j);
          }
        }
      }
      return arr;
    }

    function getProfilePic(name) {
      if (name === "You") {
        return <div style={{width: "50px", height: "50px", borderRadius: "50%", color: "white", backgroundColor: "#109cf1",
                            fontFamily: "sans-serif", fontSize: "22px", textAlign: "center", paddingTop: "8px", marginRight: "5px"}}>You</div>;
      } else {
        var avatarURL = uiavatars.generateAvatar({
          uppercase: true,
          name: name,
          color: "109cf1",
          fontsize: 0.5,
          bold: true,
          length: 2,
          rounded: true,
          size: 46,
        });
        return <img style={{marginRight: "5px"}} src={avatarURL}></img>;
      }
    }

    function createNotification(message, type, title="") {
      console.log("createNotification: " + message + " " + type + " " + title);
      
      switch (type) {
        case 'info':
          console.log("MESSAGE!!!");
          NotificationManager.info(message, title, 5000);
          break;
        case 'success':
          NotificationManager.success(message, title);
          break;
        case 'warning':
          NotificationManager.warning(message, 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error(message, 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
      
    }

    return(
      <>
        <div className = "background">
            <SetEnvironment></SetEnvironment>
            <Sidebar current="meetings" controller={props.controller}></Sidebar>
            <div className = "middle container">
                  {loading ? <LoadingOverlay/> : <></>}
                  <Headbar title = "Meetings"></Headbar>
                  <div className="horCont" style={{height: "50px", width: "96%", marginLeft: "2%", marginBottom: "7px"}}>
                      <div className="horCont" style={{width: "415px", marginTop: "25px"}}>
                        <Button style={{backgroundColor: currentWindowState === "plans" ? "white" :"#8c8c8c",
                                        color: currentWindowState === "plans" ? "black" : "white", 
                                        width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                                        fontWeight: "bold"}}
                            onClick={currentWindowState === "" ? undefined : () => setCurrentWindowState("plans")}>
                          Plans
                        </Button>
                        <Button style={{backgroundColor: currentWindowState === "files" ? "white" : "#8c8c8c",
                                        color: currentWindowState === "files" ? "black" : "white", 
                                        width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                                        fontWeight: "bold"}}
                            onClick={currentWindowState === "" ? undefined : () => setCurrentWindowState("files")}>
                          Documents
                        </Button>
                        <Button style={{backgroundColor: currentWindowState === "chat" ? "white" : "#8c8c8c",
                                        color: currentWindowState === "chat" ? "black" : "white", 
                                        width: "130px", height: "60px", border: "none", textAlign: "center", fontSize: "16px",
                                        fontWeight: "bold"}}
                            onClick={currentWindowState === "" ? undefined : () => setCurrentWindowState("chat")}>
                          Chat
                        </Button>
                      </div>
                      <div style={{width: "500px", display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "5px"}}>
                        {usersInRoom.map(user => {
                          return(
                            getProfilePic(user.username)
                          )
                        })}
                      </div>
                      <div style={{width: "125px"}}>
                        {currentWindowState !== "" ?
                          <Button variant="outline-primary" style={{fontWeight: "bold", backgroundColor: "white", borderColor: "#109cf1", color: "#109cf1"}} onClick={endMeeting}>End Meeting</Button>
                          : <></>
                        }
                      </div>
                  </div>
                  <div style={{marginLeft: "2%", width: "96%", backgroundColor: "white", borderRadius: "5px"}}>
                    {
                      currentWindowState === "plans" && !planFilterSubpage ?
                        <div className="horCont" style={{height: "50px", paddingLeft: "25px", paddingRight: "40px"}}>
                          <div><a onClick={() => {setPlanFilterSubpage(true)}}><u>{"< Filters"}</u></a></div>
                          <input className="buttonStyle" type="submit" value="Send to client" onClick={sendPlansToClient}/>
                        </div> :
                      currentWindowState === "files" ?
                        <div className="horCont" style={{height: "50px", paddingLeft: "25px", paddingRight: "40px"}}>
                          <div></div>
                          <button className="buttonStyle" style={{backgroundColor: "#109cf1", height: "50px"}} onClick={() => setUploadOverlayOn(true)}>Add Document</button>
                        </div>
                      : <></>
                    }
                    {
                      currentWindowState === "plans" ?
                        planFilterSubpage ?
                          <div style={{padding: "40px"}}> 
                            <PlanFilters
                              onSubmit={(evt) => {
                                evt.preventDefault();
                                const data = new FormData(evt.target);
                    
                                var object = {};
                                data.forEach((value, key) => {
                                    object[key] = value;
                                    window.sessionStorage.setItem(key, value);
                                });
                                console.log(object);
                    
                                setPlanFilters({
                                    type: object["plan_type"], 
                                    plan_number: object["plan_number"],
                                    zip: object["zip"],
                                    age: object["age"],
                                    gender: object["gender"],
                                    county_id: object["county"],
                                });
                                
                                setPlanFilterSubpage(false);
                              }}
                              setPlanFilters={setPlanFilters}
                              showEffectiveDate={false}
                            />
                          </div>
                        :
                          <div style={{padding: "15px"}}>
                            <Table borderless hover style={{width:"100%", marginBottom:"30px"}}>
                              <thead>
                                <tr style={{border: "none"}}>
                                  <th style={{border:"none", paddingTop:"14px", fontSize: "14px"}}></th>
                                  <th style={{border:"none", paddingTop:"14px", fontSize: "14px"}}>Name</th>
                                  <th></th>
                                  <th style={{border:"none", paddingTop:"14px", fontSize: "14px"}}>Premium</th>
                                </tr>
                              </thead>
                              <tbody>
                              {queryData === undefined || queryData.length === 0
                                ? <p style={{width: "300px"}}>There are no plans that match your search</p>
                                : [].concat(queryData)
                                  .sort((a, b) => a.premium > b.premium ? 1 : -1)
                                  .map((p, index) => (
                                    p === undefined || p.application_aws_key === null ? <></> :
                                    <PlanBox
                                        companyName = {p.carrier.name}
                                        planName = {p.name}
                                        value = {p.id}
                                        premium = {p.premium ? p.premium : "Not available"}
                                        planQuoteId = {p.plan_quote_id}
                                        premiumMultiple = {p.premiumMultiple || ""}
                                        onChange = {handleSelectPlan}
                                        key = {index}
                                        applicationAwsKey = {p.application_aws_key}
                                        index={index}
                                        style={{backgroundColor: highlightedPlan !== undefined && highlightedPlan.id === p.id ? '#ffc107' :
                                          currentlySharedPlans !== undefined && currentlySharedPlans.includes(p) ? 'rgb(124, 205, 255)' : undefined,
                                          borderTopWidth: "1px", borderColor: "#dee2e6"}}
                                        checkbox
                                        mobile={false}
                                    ></PlanBox>
                              ))}
                              </tbody>
                            </Table>
                            
                          </div>
                      :
                      currentWindowState === "files" ?
                      <div style={{padding: "15px"}}>
                        <Table borderless hover style={{width:"100%", marginBottom:"30px"}}>
                          <thead>
                            <tr style={{border: "none"}}>
                              <th style={{border:"none", width:"30px", paddingTop:"14px", fontSize: "14px"}}></th>
                              <th style={{border:"none", width:"30px", paddingTop:"14px", fontSize: "14px"}}>Name</th>
                              <th style={{border:"none", width:"30px", paddingTop:"14px", fontSize: "14px"}}>Last Updated</th>
                              <th style={{border:"none", width:"30px", paddingTop:"14px", fontSize: "14px"}}></th>
                            </tr>
                          </thead>
                          <tbody>
                          {["Sample 1.pdf", "Sample 2.pdf", "Sample 3.pdf"].map((f, index) => (
                            <tr style={{height: "40px", borderRadius: "3px", backgroundColor: sharedPdfFile === f ? "rgb(124 205 255)" : "white", width: "100%", borderTopWidth: "1px", borderColor: "#dee2e6"}}>
                              <td style={{border:"none", width:"30px"}}></td>
                              <td style={{border:"none", width:"30px"}}><label style={{width: "500px"}} for={f}>{f.replace(broker.id + "_", "").replace(".pdf", "")}</label></td>
                              <td style={{border:"none", width:"30px"}}><div style={{width: "100px"}}></div></td>
                              <td style={{border:"none", width:"30px"}}>
                                <div className="horCont" style={{marginTop: "0px", width: "80px", height: "25px", float: "right"}}>
                                  {sharedPdfFile === f ?
                                    <div>Sharing</div> :
                                    <div>
                                      <div style={{width: "30px"}}>
                                        <img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/share.png" width="25px" onClick={() => {
                                          previewFile(f);
                                        }}/>
                                      </div>
                                    </div>
                                  }
                                  <Dropdown>
                                    <Dropdown.Toggle style={{backgroundColor: "transparent", borderColor: "transparent", width:"20px", height:"40px"}}>
                                      <img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/kebab-icon.png" height="20px"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu id="dropdown-basic-button" title="">
                                      <Dropdown.Item><a class="dropdown-item" onClick={() => {previewFile(f)}}>Preview</a></Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          ))}

                          {
                          customBrokerPDFs !== undefined && customBrokerPDFs.length > 0 ?
                            customBrokerPDFs.sort((a, b) => Date.parse(a.auto_last_updated ? a.auto_last_updated : a.auto_create_date) < Date.parse(b.auto_last_updated ? b.auto_last_updated : b.auto_create_date) ? 1 : -1)
                            .map((p, index) => (
                              <>
                                <tr style={{height: "40px", borderRadius: "3px", backgroundColor: sharedPdfFile === p.pdf_name ? "rgb(124 205 255)" : "white", width: "100%", borderTopWidth: "1px", borderColor: "#dee2e6"}}>
                                  <td style={{border:"none", width:"30px"}}></td>
                                  <td style={{border:"none", width:"30px"}}><label style={{width: "500px"}} for={p.pdf_name}>{p.pdf_name.replace(broker.id + "_", "").replace(".pdf", "")}</label></td>
                                  <td style={{border:"none", width:"30px"}}><div style={{width: "100px"}}>{p.auto_last_updated ? new Date(p.auto_last_updated).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'}) : 
                                                                                      new Date(p.auto_create_date).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'})}</div></td>
                                  <td style={{border:"none", width:"30px"}}>
                                    <div className="horCont" style={{marginTop: "0px", width: "80px", height: "25px", float: "right"}}>
                                      {sharedPdfFile === p.pdf_name ?
                                        <div>Sharing</div> :
                                        <div>
                                          <div style={{width:"30px"}}></div>
                                          <img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/share.png" width="25px" onClick={() => {
                                            previewFile(p.pdf_name);
                                          }}/>
                                        </div>
                                      }
                                      <Dropdown>
                                        <Dropdown.Toggle style={{backgroundColor: "transparent", borderColor: "transparent", width:"20px", height:"40px"}}>
                                          <img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/kebab-icon.png" height="20px"/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu id="dropdown-basic-button" title="">
                                          <Dropdown.Item><a class="dropdown-item" onClick={() => {previewFile(p.pdf_name)}}>Preview</a></Dropdown.Item>
                                          <Dropdown.Item><a class="dropdown-item" onClick={() => {renameFile(p.pdf_name)}}>Rename</a></Dropdown.Item>
                                          <Dropdown.Item><a class="dropdown-item" onClick={() => {deleteFile(p.pdf_name)}}>Delete</a></Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))
                          : <></>}
                          </tbody>
                        </Table>
                        {uploadOverlayOn ?
                          <div className="modal" style={{zIndex: 4}}>
                            <div className="modal" style={{width: "80%", height: "80%", backgroundColor: "white", zIndex: 4,
                                                          marginTop: "90px", marginLeft: "-50px", padding: "40px"}}>
                              <div className="horCont">
                                <div></div>
                                <h2 style={{textAlign: "center"}}>
                                  Add a Document
                                </h2>
                                <div><img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/x-icon.png"
                                   style={{position: "fixed", width: "40px", height: "40px", marginLeft: "-40px", marginTop: "-50px"}}
                                   onClick={() => {
                                     if (uploadInProgress) {
                                       if (!window.confirm("Are you sure you want to leave? Your file(s) will continue to upload in the background.")) {
                                         return;
                                       }
                                     }
                                     setUploadOverlayOn(false);
                                   }}/></div>
                              </div>
                              <h2 style={{textAlign: "center", color: "gray", fontSize: "14px"}}>
                                Add PDFs to your library to share with clients
                              </h2>
                              <form action="/api/upload_pdf" class="dropzone" id="fileUploadArea"></form>
                              <div style={{display:"flex", justifyContent:"center"}}><button className="buttonStyle" style={{backgroundColor: "#109cf1", height: "50px", marginTop: "20px"}}
                                   onClick={() => {
                                     if (uploadInProgress) {
                                       if (!window.confirm("Are you sure you want to leave? Your file(s) will continue to upload in the background.")) {
                                         return;
                                       }
                                     }
                                     setUploadOverlayOn(false);
                                   }}>Done</button></div>
                            </div>
                          </div>
                          : <></>
                        }
                        {documentOverlayOn ?
                          <div className="modal" style={{zIndex: 4}}>
                            <div className="modal" style={{width: "80%", height: "72%", backgroundColor: "white", zIndex: 4,
                                                          marginTop: "20px", marginLeft: "-50px", padding: "40px"}}>
                              
                              <div className="horCont">
                                <div></div>
                                <h2 style={{textAlign: "center"}}>
                                  Preview Document
                                </h2>
                                <div><img src="https://planprovide-resources.s3.us-west-1.amazonaws.com/x-icon.png"
                                   style={{position: "fixed", width: "40px", height: "40px", marginLeft: "-40px", marginTop: "-50px"}} onClick={() => {
                                      setDocumentOverlayOn(false);
                                      setPageNumsToShare([]);
                                      pageNumsToShareRef.current = [];
                                      setPageNumsInTextBox("");
                                    }}/></div>
                              </div>
                              <h2 style={{textAlign: "center", color: "gray", fontSize: "14px"}}>
                                Choose which pages to send to the client
                              </h2>
                              {sharedPdfFileBlob !== null ?
                                <div style={{width: "100%"}}>
                                  <Document file={sharedPdfFileBlob}
                                            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                                            onLoadError={console.error}
                                            >
                                      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap"}}>
                                        
                                        {previewLoading ? <div>Loading...</div> : [...Array(numPages).keys()].map((p) => (
                                          <div className="vertCont"
                                               style={{width: "19%", padding: "2px", margin: "2px", borderRadius: "10px",
                                                       backgroundColor: currentlySharedPdfPages !== undefined && 
                                                       currentlySharedPdfPages.includes(p) &&
                                                       sharedPdfFile === previewingPdfFile ? 'rgb(124, 205, 255)' : undefined,
                                                      }}>
                                            <div className="horCont" style={{width: "20%"}}>
                                              <div style={{width: "20px"}}>
                                                <input type="checkbox" style={{width: "20px", height: "20px"}}
                                                      onChange={() => {
                                                          if (pageNumsToShare.includes(p)) {
                                                            if (pageNumsToShare.length === 1) {
                                                              setPageNumsToShare([]);
                                                              setPageNumsInTextBox("");
                                                            } else {
                                                              setPageNumsToShare(pageNumsToShare.filter(page => page !== p));
                                                              setPageNumsInTextBox(numArrayToRangeString(pageNumsToShare.filter(page => page !== p)));
                                                            }
                                                          } else {
                                                            setPageNumsToShare([...pageNumsToShare, p].sort((a, b) => a - b));
                                                            setPageNumsInTextBox(numArrayToRangeString([...pageNumsToShare, p].sort((a, b) => a - b)));
                                                          }
                                                      }}
                                                ></input>
                                              </div>
                                              <Page pageNumber={p + 1}
                                                    width={window.innerWidth * 0.1}
                                                    debug />
                                            </div>
                                            <div>
                                              Page {p + 1} of {numPages}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                  </Document>
                                  <div style={{position: "fixed", top: "74%", left: "150px", height: "110px", width: "80%", paddingLeft: "40px", paddingRight: "40px", backgroundColor: "white"}}>
                                    <div><b>Pages selected: {pageNumsInTextBox}</b></div>
                                    <button className="buttonStyle" style={{marginRight: "10px", marginTop:"10px"}} onClick={() => {sendPdfFile()}}>Send</button>
                                  </div>
                                </div> : <></>
                              }
                            </div>
                          </div>
                          : <></>
                        }
                      </div>
                      :
                      currentWindowState === "chat" ?
                      <div style={{padding: "40px"}}>
                        <div id="log" style={{height: "300px", overflowY: "scroll"}}>
                          {messages.map((message, index) => {
                            return(<div style={{marginLeft: broker.id === message.websocket_id ? "20%" : "0px",
                                                marginRight: broker.id === message.websocket_id ? "0px" : "20%",}}>
                              <div className="horCont" style={{fontSize: "12px"}}>
                                <div>{broker.id === message.websocket_id ? "You" : message.sender}</div>
                                <div>{message.time}</div>
                              </div>
                              <div style={{fontSize: "14px",
                                          border: message.websocket_id === "" ? undefined : "1px solid",
                                          borderRadius: "5px", borderColor: "#E4E9EE",
                                          padding: message.websocket_id === "" ? "0px" : "10px",
                                          backgroundColor: broker.id === message.websocket_id ? "#109CF1" : undefined,
                                          color: broker.id === message.websocket_id ? "white" : undefined,
                                          fontWeight: message.websocket_id === "" ? "bold" : undefined}}>
                                {message.data}
                              </div>
                            </div>);
                          })}
                          <div style={{ float:"left", clear: "both" }}
                               ref={messagesEndRef}>
                          </div>
                        </div>
                        <form onSubmit={(e) => {
                          e.preventDefault();
                          if(logMessageRoom !== "") {
                            setLogMessageRoom("");
                            printToLogRoom();
                          }
                        }}>
                          <input type="textarea" onChange={(e) => {e.target.style.height = 'inherit'; 
                                                                 e.target.style.height = `${e.target.scrollHeight}px`;
                                                                 setLogMessageRoom(e.target.value);
                                                                }}
                                    style={{width: "100%", height: "40px", borderRadius: "5px",}}
                                    value={logMessageRoom} placeholder="Message"/>
                          <input className="buttonStyle" type="submit" value="Send a chat message" 
                                style={{marginTop: "10px", width: "185px"}}/>
                        </form>
                      </div>
                      : <></>
                    }
                </div>
            </div>
            <NotificationContainer/>
        </div>
      </>
    );
}

export default withRouter(LiveSalesSessionBrokerView);
