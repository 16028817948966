import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

import './sidebar.css'

import { BrokerContext } from './BrokerContext';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from './ENVContext';

function Sidebar(props) {
    let history = useHistory();
    let current = props.current;

    const [ENV, setENV] = useContext(ENVContext);
    const [broker, setBroker] = useContext(BrokerContext);

    // Force refresh
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        forceUpdate();
    }, [broker.live_session_room_id]);

    const fetchImage = async (url) => {
        let config = {
            method: "GET",
            headers: {},
            mode: "cors",
            cache: "default",
        };
        await fetch(url, config)
        .then(res => res.blob())
        .then(imageBlob => {
            return URL.createObjectURL(imageBlob);
        });
    };

    const { data: logoImageQuery, status: logoImageQueryStatus } = useQuery("logoImage", fetchImage, "https://planprovide-resources.s3.us-west-1.amazonaws.com/PlanProvide-logo-black.png");

    const uiavatars = require("ui-avatars");

    // const PlanProvideLogo = React.memo(function PlanProvideLogo({ src, width, style }) {
    //     return <img src={src} width={width} style={style} />;
    // });

    function trackUse(destination) {
        mixpanel.init(ENV.MIXPANEL_TOKEN);
        mixpanel.track("Nav to " + destination);
    }

    function getProfilePic() {
      let name;
      if (broker.agency_name) {
        name = broker.agency_name;
      }
      else {
        name = broker.first_name + " " + broker.last_name;
      }
      var avatarURL = uiavatars.generateAvatar({
        uppercase: true,
        name: name,
        // background: "990000",
        color: "000000",
        fontsize: 0.5,
        bold: true,
        length: 2,
        rounded: true,
        size: 46,
      });
      return avatarURL;
    }

    return (
        <div className = "menu">
            <div id = "sidebar">
                <div style = {{marginTop: "105px", fontWeight: "bold", color: "#109CF1", marginLeft: "40px", fontSize: "22px"}}>
                    {props.current !== undefined ? props.current.charAt(0).toUpperCase() + props.current.slice(1) : ""}
                </div>
                <div className = "horCont profileInfo" style={{justifyContent: "normal", marginLeft: "73px", marginTop: "15px"}}>
                    <div className = "profilePic">
                        <img width="45px" src={getProfilePic()}></img>
                    </div>
                    <div style={{marginLeft: "10px"}}>
                        <div className = "profileName" style={{textAlign: "left", marginTop: '0px'}}>
                            <div>
                                {
                                    broker.agency_name == ''
                                ?   <p id = "profileName" style={{marginBottom: "3px"}}>{`${broker.first_name} ${broker.last_name}`}</p>
                                :   <p id = "profileName" style={{marginBottom: "3px"}}>{broker.agency_name}</p>
                                }
                            </div>
                            <div style={{color: "#90A0B7"}}>
                                <p id = "profileEmail" style={{textOverflow: "ellipsis", overflow: "hidden", width: "145px"}}>{broker.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className = "pageDirect" style={{marginTop: "-5px"}}>
                    {
                      props.hideContents ? <></> :
                      <>
                      {
                          current === "dashboard"
                          ? <Link style = {{color: "#109CF1", backgroundColor: "#FAF9F8"}} className = "directCon" id = "dashboard" to = '/dashboard' onClick={() => {trackUse("dashboard"); props.controller.abort()}}>Dashboard</Link>
                          : <Link className = "directCon" id = "dashboard" to = '/dashboard' onClick={() => {trackUse("dashboard"); props.controller.abort()}}>Dashboard</Link>
                      }
                      {
                          broker.role === 'owner' ?
                            current === "agency"
                            ? <Link style = {{color: "#109CF1", backgroundColor: "#FAF9F8"}} className = "directCon" id = "agency" to = '/agency/dashboard' onClick={() => {trackUse("agency page"); props.controller.abort()}}>Agency</Link>
                            : <Link className = "directCon" id = "agency" to = '/agency/dashboard' onClick={() => {trackUse("agency page"); props.controller.abort();}}>Agency</Link>
                          : <></>
                      }
                      {
                          current === "clients"
                          ? <Link style = {{color: "#109CF1", backgroundColor: "#FAF9F8"}} className = "directCon" id = "clients" to = '/clients' onClick={() => {trackUse("clients page"); props.controller.abort();}}>Clients</Link>
                          : <Link className = "directCon" id = "clients" to = '/clients' onClick={() => {trackUse("clients page"); props.controller.abort();}}>Clients</Link>

                      }
                      {
                          current === "applications"
                          ? <Link style = {{color: "#109CF1", backgroundColor: "#FAF9F8"}} className = "directCon" id = "applications" to = '/applications/applications-list'
                                  onClick={() => {
                                    trackUse("applications page");
                                    props.controller.abort();
                                    history.push(`/applications/applications-list`);
                                  }}>Applications</Link>
                          : <Link className = "directCon" id = "applications" to = '/applications/applications-list' onClick={() => {trackUse("applications page"); props.controller.abort()}}>Applications</Link>
                      }
                      {
                        <div className="horCont" style={{marginTop:"0px"}}>{
                            current === "meetings"
                            ? <Link style = {{color: "#109CF1", backgroundColor: "#FAF9F8"}} className = "directCon" id = "meetings" to = '/meetings' onClick={() => {trackUse("meetings page"); props.controller.abort()}}>Meetings</Link>
                            : <Link className = "directCon" id = "meetings" to = '/meetings' onClick={() => {trackUse("meetings page"); props.controller.abort();}}>Meetings</Link>
                        }{
                            broker.live_session_room_id === null || broker.live_session_room_id === undefined
                            ? <></> : <div style={{backgroundColor: "red", color: "white", padding: "5px", borderRadius: "5px", width:"46px"}}>LIVE</div>
                        }</div> 
                      }
                      {
                          current === "settings"
                          ? <Link style = {{color: "#109CF1", backgroundColor: "#FAF9F8"}} className = "directCon" id = "settings" to = '/profile' onClick={() => {trackUse("profile page"); props.controller.abort()}}>Profile</Link>
                          : <Link className = "directCon" id = "settings" to = '/profile' onClick={() => {trackUse("profile page"); props.controller.abort()}}>Profile</Link>
                      }
                      {
                          current === "help"
                          ? <Link style = {{color: "#109CF1", backgroundColor: "#FAF9F8"}} className = "directCon" id = "help" to = '/help' onClick={() => {trackUse("help page"); props.controller.abort()}}>Help</Link>
                          : <Link className = "directCon" id = "help" to = '/help' onClick={() => {trackUse("help page"); props.controller.abort()}}>Help</Link>
                      }
                      </>
                    }
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;
