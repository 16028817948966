import React, { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { BrokerContext } from '../components/BrokerContext';
import { ApplicationContext } from '../components/PCAContext';

import './pages.css';
import './Profile.css';

import Banner from '../components/Banner';
import BrokerProfileForm from '../components/BrokerProfileForm';
import Headbar from '../components/Headbar';
import InputField from '../components/InputField';
import ProfileNavigation from '../components/ProfileNavigation';
import SetEnvironment from '../components/SetEnvironment';
import Sidebar from '../components/Sidebar';
import StateForm from '../components/StateForm';
import LoadingOverlay from '../components/LoadingOverlay';
import WritingNumbers from '../components/WritingNumbers';

import mixpanel from 'mixpanel-browser';
import { ENVContext } from '../components/ENVContext';

function Profile(props) {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [broker, setBroker] = useContext(BrokerContext);
    const [application, setApplication] = useContext(ApplicationContext);
    const [showBanner, setShowBanner] = useState(false);
    const [writingNumbers, setWritingNumbers] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      console.log(showBanner)
    }, [showBanner])

    useEffect(() => {
      setApplication({
          id: -1,
          status: -1,
          url: '',
          download: '',
          csv: '',
          envelope_id: '',
          access_code: '',
          effective_date: '',
      });
    }, [])

    const fetchBrokerId = async (key, broker_id) => {
        if (broker_id === -1) { return; }
        let json = JSON.stringify({broker_id: broker_id});
        const res = await fetch('/api/get_broker_by_id', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: json
        }).then(res => res.json())
        .then(data => {
          if (data && data.id !== -1) {
            setBroker({
                ...broker,
                first_name: data.first_name,
                last_name: data.last_name,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                email: data.email,
                agency_name: data.agency_name,
                agency_npn: data.agency_npn,
                broker_npn: data.broker_npn,
                phone: data.phone,
                status: data.status,
                signing_url: data.signing_url,
                ds_access_code: data.ds_access_code,
                role: data.role,
                agency_id: data.agency_id,
            });
        }
      })
    };

    const { data, status } = useQuery(['broker', broker.id], fetchBrokerId, {
      refetchOnWindowFocus: false, refetchOnMount: false,
      refetchOnReconnect: false,
    });

    function auth0Logout() {
      mixpanel.init(ENV.MIXPANEL_TOKEN);
      mixpanel.track("User logged out")
      mixpanel.reset();
      window.open(window.location.origin + '/auth0/logout', '_self');
    }

    function updateProfile(object) {
      window.scrollTo(0,0);
      setLoading(true);

      object["id"] = broker.id;

      var json = JSON.stringify(object);

      mixpanel.init(ENV.MIXPANEL_TOKEN);
      mixpanel.track("Updated broker profile");
      // Uses POST method to send json object of data to Flask
      fetch('/api/update_broker' , {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: json,
        referrer: '',
      }
      ).then(res => {
        // Checks that external services return ok, throws 400-500 errors automatically made by the system
        if (res.ok) {
          console.log(res);
          return res.json();
        } else {
          let errorMessage = "Uh oh. Error"

          // Sends you to error page
          history.push(`/error/${errorMessage}`)
          return;
        }
      }
      ).then(data => {
        setBroker({
            ...broker,
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            address: data.address,
            city: data.city,
            state: data.state,
            zip: data.zip,
            email: data.email,
            agency_name: data.agency_name,
            agency_npn: data.agency_npn,
            broker_npn: data.broker_npn,
            phone: data.phone,
            status: data.status,
            signing_url: data.signing_url,
            ds_access_code: data.ds_access_code,
            role: data.role,
            agency_id: data.agency_id,
            live_session_room_id: data.live_session_room_id,
            live_session_create_date: data.live_session_create_date,
            live_session_client_name: data.live_session_client_name,
        });

        // Checks for errors thrown by internal services, sends to error page
        if (data["status"] == "error") {
          let errorMessage = data["errorMessage"];
          history.push(`/error/${errorMessage}`)
        }

        let jsonWritingNums = JSON.stringify({
          broker_id: data.id,
          writing_nums: writingNumbers,
          update_all: true,
        });

        const resWritingNums = fetch('/api/update_broker_writing_numbers', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: jsonWritingNums,
        }).then(res => {
          setLoading(false);
          setShowBanner(true);
        });
      });

     return Promise.resolve("Dummy response to keep the console quiet");
    }

    return(
      <div className = "background">
        <SetEnvironment></SetEnvironment>
        {
          loading && <LoadingOverlay></LoadingOverlay>
        }
        <Sidebar current = "settings" controller={props.controller}></Sidebar>
        <div className = "middle container">
          <Headbar title="Profile"></Headbar>
          {showBanner ?
              <Banner message={"Profile updated!"}></Banner>
              : <></>
          }
          <ProfileNavigation current="profile"/>
          {
            status === 'error' && (<div style = {{marginTop: "10px"}} className = "vertCont">Error fetching broker information</div>)
          }
          {
            status === 'loading' && (<LoadingOverlay/>)
          }
          {
            status === 'success' && (
              <>
                <BrokerProfileForm
                    broker={broker}
                    handleSubmit={updateProfile}
                    canEditEmail={false}
                    buttonText="Update information"
                    logoutFunc={auth0Logout}
                    className="None"
                    controller={props.controller}
                    isBrokerProfile
                    hideWritingNums />
              </>
            )
          }
        </div>
      </div>
    );
}

export default withRouter(Profile);
