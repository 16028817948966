import React, { useContext, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ENVContext } from './ENVContext';
import { BrokerContext } from './BrokerContext';

import mixpanel from 'mixpanel-browser';

const getUsername = async () => {
    const res = await fetch('/auth0/get_username', {});
    return res.json();
}

function SetEnvironment() {
    let history = useHistory();

    const [ENV, setENV] = useContext(ENVContext);
    const [broker, setBroker] = useContext(BrokerContext);

    const { data, status } = useQuery(['username'], getUsername, {
      refetchOnWindowFocus: true, refetchOnMount: true,
      refetchOnReconnect: true,
    });

    useEffect(() => {
        if (status === 'success') {
            if (data == '') {
                mixpanel.init(ENV.MIXPANEL_TOKEN);
                mixpanel.reset();
                window.open(window.location.origin + '/auth0/logout', '_self');
            } else {
                if (broker.id == -1) {
                    let obj = {
                        broker_email: data,
                    }
                    let json = JSON.stringify(obj);
                    fetch('/api/get_broker_by_email', {
                        method: 'POST',
                        headers : {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        body: json,
                    }).then(res => res.json())
                    .then(info => {
                        if (info == "No broker") {
                            mixpanel.init(ENV.MIXPANEL_TOKEN);
                            mixpanel.track("Sent to registration")
                            setBroker({...broker, email: data});
                            history.push('/register/agency-type');
                        } else {
                            mixpanel.init(ENV.MIXPANEL_TOKEN);
                            mixpanel.identify(info.id);
                            // if current time is 3 hours or later than create date
                            console.log(info.live_session_create_date);
                            console.log(Date.parse(info.live_session_create_date));
                            console.log(Date.parse(info.live_session_create_date) + (3 * 60 * 60 * 1000));
                            console.log(Date.now());
                            if (info.live_session_create_date !== null && Date.parse(info.live_session_create_date) + (3 * 60 * 60 * 1000) < Date.now()) {
                                setBroker({
                                    id: info.id,
                                    first_name: info.first_name,
                                    last_name: info.last_name,
                                    email: info.email,
                                    agency_name: info.agency_name,
                                    agency_npn: info.agency_npn,
                                    broker_npn: info.broker_npn,
                                    phone: info.phone,
                                    status: info.status,
                                    signing_url: info.signing_url,
                                    ds_access_code: info.ds_access_code,
                                    app_edit_allowed: info.app_edit_allowed,
                                    role: info.role,
                                    agency_id: info.agency_id,
                                    live_session_client_name: null,
                                    live_session_create_date: null,
                                    live_session_room_id: null,
                                });
                                fetch('/api/update_broker', {
                                    method: 'POST',
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Accept': 'application/json',
                                    },
                                    body: JSON.stringify({
                                      id: info.id,
                                      live_session_client_name: null,
                                      live_session_create_date: null,
                                      live_session_room_id: null,
                                    })
                                });
                            }
                            else {
                                setBroker({
                                    id: info.id,
                                    first_name: info.first_name,
                                    last_name: info.last_name,
                                    email: info.email,
                                    agency_name: info.agency_name,
                                    agency_npn: info.agency_npn,
                                    broker_npn: info.broker_npn,
                                    phone: info.phone,
                                    status: info.status,
                                    signing_url: info.signing_url,
                                    ds_access_code: info.ds_access_code,
                                    app_edit_allowed: info.app_edit_allowed,
                                    role: info.role,
                                    agency_id: info.agency_id,
                                    live_session_room_id: info.live_session_room_id,
                                    live_session_create_date: info.live_session_create_date,
                                    live_session_client_name: info.live_session_client_name,
                                });
                            }
                        }
                    });
                }
            }
        }
    }, [status]);

    return (<></>);
}

export default SetEnvironment;
